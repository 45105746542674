<template>
  <div class="content category" :style="'min-height: '+minHeight+'px;'">
    <section class="container">
      <div style="background-color: #fff; padding: 17px 20px">
        <el-page-header @back="goBack" content="">
        </el-page-header>
      </div>
    </section>

    <section class="container course-list" :style="'min-height: '+listMinHeight+'px;'">
      <el-row>
        <el-input class="mgt10" placeholder="搜索课程" size="small" v-model="title">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-row>
      <el-row>
        <el-col class="course-item" :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in list" :key="item.id">
          <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11">
            <el-image class="cover" :src="item.coverimage" fit="fill"></el-image>
          </el-col>
          <el-col class="info" :xs="13" :sm="13" :md="13" :lg="13" :xl="13">
            <p class="title pointer">{{item.title}}</p>
            <div>
              <el-button @click="toVideo(item.id)" v-show="item.videovid.length>0" class="btn1 btn-video" size="mini"
                round plain>视频
              </el-button>
              <el-button @click="toPic(item.id)" v-show="item.picimages.length>0" class="btn1 btn-pic" size="mini" round
                plain>
                图纸</el-button>
            </div>
          </el-col>
        </el-col>
      </el-row>

      <!-- <el-empty v-show="emptyShow" description="暂无内容"></el-empty> -->

      <infinite-loading spinner="bubbles" :identifier="infiniteId" @infinite="infiniteHandler" ref="infiniteLoading"
        class="more-nav container">
        <div slot="spinner">努力加载中</div>
        <div slot="no-more">没有更多数据</div>
        <div slot="no-results">暂无数据</div>
      </infinite-loading>

    </section>
    <!-- <m-loadmore :status="more" :onRichBottom="loadMore"></m-loadmore> -->

  </div>
</template>

<script>
  import { infoList } from "../../api";
  //import loadMore from "../loadmore/Index";
  import InfiniteLoading from 'vue-infinite-loading';
  export default {
    components: {
      //'m-loadmore': loadMore,
      InfiniteLoading
    },
    data() {
      return {
        more: 'more',
        disabled: false,
        page: 1,
        cid: this.$route.query.id,
        list: [],
        minHeight: window.innerHeight,
        listMinHeight: window.innerHeight - 66,
        emptyShow: false,
        title: this.$route.query.title,
        top: 0,
        infiniteId: +new Date()
      };
    },
    mounted() {
      //this.getInfoList();
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      // loadMore() {
      //   if (!this.disabled) {
      //     this.getInfoList();
      //   }
      // },
      infiniteHandler($state) {
        this.getInfoList($state);
      },
      getInfoList($state) {
        this.more = 'loading';
        const self = this;
        let data = {
          page: self.page,
          id: self.cid,
          keyword: self.title
        };
        infoList(data).then((res) => {
          console.log(res, $state);
          if (res.data.data.length) {
            this.page++;
            self.list = self.list.concat(res.data.data);
            $state?.loaded();

          } else {
            $state?.complete();
          }
        });
      },
      toVideo(id) {
        const self = this;
        self.$router.push(`/video?id=${id}`);
      },
      toPic(id) {
        const self = this;
        self.$router.push(`/steppic?id=${id}`);
      },
      search() {
        //this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded');
        this.page = 1;
        this.list = [];
        this.infiniteId += 1;
        //this.getInfoList();
      },
    },
  };
</script>

<style scoped>
  .course-list {
    background-color: #fff;
    padding: 0 20px 50px;
    margin-top: 8px;
  }

  .course-item {
    cursor: pointer;
    font-size: 0;
    margin-top: 20px;
  }

  .course-item .cover {
    border-radius: 5px;
    border: 1px solid #f7f7f7;
  }

  .course-item .info {
    font-size: 14px;
    padding: 5px 10px;
    color: #333;
  }

  .btn1 {
    height: 37px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 20px;
  }

  .btn1.btn-video {
    background-color: aliceblue;
  }

  .btn1.btn-pic {
    background-color: cornsilk;
  }

  .more-nav {
    margin-top: 20px;
    color: #cfcfcf;
  }
</style>